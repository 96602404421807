(function() {
  var app = angular.module("app");

  app.run(["functions", function(functions) {
    // functions.loading();
  }]);

  app.controller('OrganizationController', ['$scope', '$rootScope', '$http', 'functions', function($scope, $rootScope, $http, functions) {
    $scope.show_logo = false;
    $scope.show_picture = false;
    $scope.delete_contacts = true;
    $scope.requestor = {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      authority: ""
    };
    $scope.newcontact = {};
    $scope.children = [];
    $scope.new_status = null;
    $scope.statuses = [];
    $scope.locations = [];
    $scope.filters = [];
    $scope.filterActive = false;

    $scope.organization = { organization_id: 0 }

    $scope.step = 1;
    $scope.steps = 4;

    $scope.movePreviousStep = function() {
      $scope.step--;
      if ($scope.step <= 1) {
        $scope.step = 1;
      }
    };

    $scope.moveNextStep = function() {
      if ($scope.organization_form.$valid) {
        switch (false) {
          case $scope.step !== $scope.steps:
            $scope.save();
            break;
          case !($scope.step >= $scope.steps):
            $scope.step = $scope.steps;
            break;
          default:
            $scope.step++;

            // Auto fill contact information
            if($scope.step === 2 && $scope.organization.organization_id === 0){
              $scope.organization.contact_phone = $scope.organization.contact_phone || $scope.organization.requestor.phone;
              $scope.organization.contact_email = $scope.organization.contact_email || $scope.organization.requestor.email;
            }
        }
      }
    }

    /**
     * Creates a new object with default values.
     */
    $scope.create = function() {
      functions.loading();

      $http.get('/ajaxdata/get_schema/organization').
      success(function(data, status, headers, config) {
        // data.approved 				= 1;
        data.organization_status_id = 1; // Managed

        // Reset id
        data.scale_quantity_id = undefined;

        data.children = [];
        $scope.populate(data);
      }).
      error(function(data, status, headers, config) {
        error("Error Loading", data, true, null);
      });
    }

    $scope.showError = function(ngModelController, error) {
      return $scope.organization_form.$dirty && ngModelController.$error[error];
    };

    $scope.showFilter = function(index) {
      $scope.original_filters = angular.copy($scope.filters);
      $scope.open_filter = index;

      // Fix a bug in Firefox's max-height calculation of our overlay.
      var height = jQuery(window).height() * 0.9;
      jQuery(".overlay-bounds").css({
        "max-height": height + "px"
      });

      // Show filter
      jQuery("#filter_" + $scope.open_filter).appendTo("#overlay").removeClass("hidden");
      jQuery("#overlay").animate({
        opacity: "toggle"
      }, 300);
    };

    $scope.saveFilter = function(index) {
      // $scope.filters is already bound, just run a new search. No need to use the delay here.
      $rootScope.reload_search = true;
      $scope.setfilters();
      $scope.hideTopic(index);
    };

    $scope.cancelFilter = function(index) {
      // $scope.filters = angular.copy($scope.original_filters);
      // $scope.original_filters = null;
      $scope.hideTopic(index);

      $scope.open_filter = false;
    };

    $scope.clearFilters = function(index) {
      $scope.filters[$scope.open_filter].value = [];
      $scope.filters[$scope.open_filter].label = undefined;
      $scope.original_filters = null;
      $rootScope.reload_search = true;
      $scope.hideTopic(index);
      jQuery(".topic-tile.highlight").removeClass("highlight");
      $scope.open_filter = false;
      $scope.setfilters();
    };

    $scope.hideTopic = function(index) {
      if ($scope.open_filter !== false) {
        jQuery("#filter_" + $scope.open_filter).appendTo("#filter_return_" + $scope.open_filter).addClass("hidden");
        jQuery("#overlay").animate({
          opacity: "toggle"
        }, 300, function() {
          $scope.open_filter = false;
        });
      }
    };

    $scope.selectTopic = function(e, topic) {
      if (e != null) {
        e.preventDefault();
      }
      var obj = jQuery("#topic_tile_" + topic.parent_tag_id + "_" + topic.tag_id + "_" + topic.topic_id);
      obj.toggleClass("highlight");
      var selected = obj.hasClass("highlight");

      if (!$scope.filters[$scope.open_filter].value || typeof $scope.filters[$scope.open_filter].value != "object") {
        $scope.filters[$scope.open_filter].value = new Array();
      }

      $scope.organization_form.$dirty = true;

      // Box was clicked, add to filter array.
      if (selected) {
        $scope.filters[$scope.open_filter].value.push({
          id: topic.tag_id,
          name: topic.topic
        });
      } else {
        // Box was unclicked, locate index and remove from filters array.
        var index = false;
        for (let i in $scope.filters[$scope.open_filter].value) {
          if ($scope.filters[$scope.open_filter].value[i].id == topic.tag_id) {
            index = i;
            break;
          }
        }

        if (index !== false) {
          $scope.filters[$scope.open_filter].value.splice(index, 1);
        }
      }
    };

    $scope.setfilters = function() {
      $scope.filterActive = true;

      for (let i in $scope.filters) {
        $scope.filters[i].label = "";

          for (let j in $scope.filters[i].value) {
            $scope.filters[i].label += $scope.filters[i].value[j].name;
            if (j < $scope.filters[i].value.length - 1) {
              $scope.filters[i].label += ', ';
            }
          }
      }
    };

    try {
      $scope.org_ids = JSON.parse(getCookie("organization_ids"));
    } catch (e) {
      $scope.org_ids = [];
    }

    functions.loading();

    $http.get('/ajaxdata/get_all/location').
    success(function(data, status, headers, config) {
      functions.loaded();
      $scope.locations = data;
    }).
    error(function(data, status, headers, config) {
      functions.loaded();
    });

    $scope.populate = function(data) {
      if (data.transport_limit == null) {
        data.transport_limit_unit = (country_id == 2 ? "mi" : "km");
      }
      if (data.pickup_limit == null) {
        data.pickup_limit_unit = (country_id == 2 ? "mi" : "km");
      }
      if (data.pickup == null) {
        data.pickup = 0;
      }

      $scope.organization = data;

      // CKEditor stuff
      if(window.editor) {
        window.editor.setData($scope.organization.description || '');
      }

      $scope.show_logo = $scope.organization.logo_file != null;
      $scope.show_picture = $scope.organization.picture_file != null;

      // We must delay a bit before trying to load the existing tokens.
      // This is to give AngularJS time to create the inputs and fire the "last one" event handler to build TokenInputs.
      setTimeout(function () {
        if(window.location.href.indexOf("members/organization/") == -1){
          var count = 0;
          var other = jQuery("#grouping_tag_ids");

          try {
            other.tokenInput("clear");
          } catch (e) {}

          // Clear token inputs
          for (var i = 0; i < data.tags.length; i++) {
            var tag = data.tags[i];

            if (tag.parent_tag_id != null) {
              var ti = jQuery("#tag-" + tag.parent_tag_id);

              try {
                ti.tokenInput("clear");
              } catch (e) {}
            }
          }

          // Repopulate token inputs
          for (var i = 0; i < data.tags.length; i++) {
            count++;

            var tag = data.tags[i];

            if (tag.child_tags == null) {
              tag.child_tags = [];
            }

            if (typeof tag.child_tags == "string") {
              tag.child_tags = JSON.parse(tag.child_tags);
            }

            if (tag.parent_tag_id == null) {
              for (var j = 0; j < tag.child_tags.length; j++) {
                try {
                  other.tokenInput("add", {
                    id: tag.child_tags[j].tag_id,
                    name: tag.child_tags[j].topic
                  });
                } catch (e) {}
              }
            } else {
              for (var j = 0; j < tag.child_tags.length; j++) {
                ti = jQuery("#tag-" + tag.parent_tag_id);

                try {
                  ti.tokenInput("add", {
                    id: tag.child_tags[j].tag_id,
                    name: tag.child_tags[j].topic
                  });
                } catch (e) {}
              }
            }
          }
        }else{
          $scope.open_filter = 0;
          for (let k in data.tags) {
            var tag = data.tags[k];

            if (tag.child_tags == null) {
              tag.child_tags = "[]";
            }

            if(tag.child_tags.length) {
              for (let i = 0; i < tag.child_tags.length; i++) {
                $scope.selectTopic(null, tag.child_tags[i]);
                $scope.setfilters();
              }
            }
            var obj = jQuery("#tag_textarea_" + $scope.open_filter);
            obj.text($scope.filters[$scope.open_filter].label);
            $scope.open_filter++;
          }
        }
        // setTimeout(function(){ jQuery("#name").focus(); }, 10);
        functions.loaded();
      }, 2000);
    };

    $scope.get_children = function(id) {
      functions.loading();

      $http.get('/ajaxdata/get_related_organizations/' + id).
      success(function(data, status, headers, config) {
        $scope.children = data;
        functions.loaded();
      }).
      error(function(data, status, headers, config) {
        error("Error Loading Related Organizations", data, true, null);
        functions.loaded();
      });
    }
    /**
     * Load an existing object.  If not found, a new object will be created.
     * @param  int id ID of the object to load.
     */
    $scope.load = function(id) {
      if (id <= 0) {
        $scope.create();
        return;
      }

      functions.loading();

      $scope.steps = 3;

      $http.get('/ajaxdata/get/organization/' + id).
      success(function(data, status, headers, config) {
        $scope.populate(data);
        $scope.get_children(id);
      }).
      error(function(data, status, headers, config) {
        $scope.create();
        error("Error Loading", data, true, null);
      });
    };

    $scope.setResponse = function(response) {
      $scope.gRecaptchaResponse = response;
    }

    $scope.canSave = function() {
      return $scope.organization_form.$dirty && $scope.organization_form.$valid
    };

    /**
     * Save the loaded object.
     * @param  string action Action to perform upon successful save: Empty string, close, or new.
     */
    $scope.save = function(action, event) {
      if (window.location.href.indexOf("admin/") !== -1 || $scope.organization.organization_id > 1) {
        $scope._save(action, event, "/admin/organization_save", null);
      } else {
        $scope.submit_for_approval();
      }
    };

    $scope.submit_for_approval = function() {
      $scope.organization.approved = 0;
      $scope._save("", null, "/members/submit_organization", function() {
        success("Your Request Was Sent", "You will be contacted once your request has been approved.", true, function() {
          window.location = "/";
        });
      });
    };

    $scope._save = function(action, event, save_url, callback) {
      if ($scope.organization_form.$invalid) {
        return;
      }

      $scope.organization_form.$setPristine();

      if (save_url == "/members/submit_organization") {
        wait("Sending Request", "This dialog will close automatically.");
      } else {
        wait("Saving...");
      }

      $scope.newtags = [];
      if (window.location.href.indexOf("admin/") !== -1) {
        for (var k in $scope.system_tags) {
          if ($scope.system_tags[k].require_child_selection == 1) {
            var parent_id = $scope.system_tags[k].tag_id;
            var parent_name = $scope.system_tags[k].name;

            // if(jQuery("#tag-" + parent_id).tokenInput("get"))
            // {
            // 	error("Tags Required", "You must select one or more tags for \"" + parent_name + "\"", true, function()
            // 	      {
            // 	      	setTimeout(function() { jQuery("#token-input-tag-" + parent_id).focus(); }, 10);
            // 	      });

            // 	return;
            // }

            if (jQuery("#tag-" + parent_id).size() > 0) {
              var x = {
                tag_id: parent_id,
                tags: jQuery("#tag-" + parent_id).tokenInput("get")
              };
              $scope.newtags.push(x);
            }
          }
        }
        if (jQuery("#grouping_tag_ids").size() > 0) {
          var x = {
            tag_id: null,
            tags: jQuery("#grouping_tag_ids").tokenInput("get")
          };
          $scope.newtags.push(x);
        }
      } else {
        for (let filter in $scope.filters) {

            var parent_id = $scope.filters[filter].tag_id;
            var tag = undefined;
            var tags = [];

            for (let k in $scope.filters[filter].value) {
              tag = {
                id: $scope.filters[filter].value[k].id,
                name: $scope.filters[filter].value[k].name
              }

              tags.push(tag);
            }

            var x = {
              tag_id: parent_id,
              tags: tags
            };

            $scope.newtags.push(x);
        };
      }

      $scope.organization.tags = angular.copy($scope.newtags);
      $scope.organization.description = window.editor.getData();

      var formData = new FormData();

      try {
        formData.append("logo_file_upload", document.getElementById("logo_file_upload").files[0]);
      } catch (e) {} // No new file was specified.

      try {
        formData.append("picture_file_upload", document.getElementById("picture_file_upload").files[0]);
      } catch (e) {} // No new file was specified.

      formData = functions.appendToFormData(formData, $scope.requestor);
      formData = functions.appendToFormData(formData, $scope.organization);

      $http({
        method: 'POST',
        url: save_url,
        data: functions.appendToFormData(formData, {
          'g-recaptcha-response': $scope.gRecaptchaResponse
        }),
        headers: {
          'Content-Type': undefined
        }
      }).
      success(function(data, status, headers, config) {
        if (typeof callback == "function") {
          // functions.done();
          callback();
          return;
        }

        functions.save($scope.organization_form); // "Parent" save()
        $scope.populate(data);
        $scope.get_children(data.organization_id);
        functions.done();
        if (action == "close") {
          if (window.location.href.indexOf("admin/") !== -1) {
            window.location = "/admin/organizations";
          } else {
            nerivon_alert("PROFILE UPDATED", "Your profile was updated", "success", null, function(inputValue)
            {
              window.location = "/members";
            });
          }
        } else if (action == "new") {
          $scope.create();
        }
      }).
      error(function(data, status, headers, config) {
        error("Error Saving", data, true, null);
      });
    };

    $scope.modified = function() {
      var fa = jQuery("button:focus").find("em.fa");

      if (fa.closest("form").hasClass("ng-dirty")) {
        nerivon_confirm("Unsaved Changes", "You have unsaved changes.\n\nThis action will also save your changes.\n\nContinue?", "warning", true, function() {
          fa.closest("button").focus();
          $scope.save();
        });
      } else {
        $scope.save();
      }
    };

    jQuery("#logo_file_upload, #picture_file_upload").change(function() {
      var id = jQuery(this).attr("id");
      var oFReader1 = new FileReader();
      oFReader1.readAsDataURL(document.getElementById(id).files[0]);

      oFReader1.onload = function(oFREvent) {
        var img = jQuery("#" + id.replace("upload", "preview"));

        img.attr("src", oFREvent.target.result);

        if (id == "logo_file_upload") {
          $scope.show_logo = true;
        } else if (id == "picture_file_upload") {
          $scope.show_picture = true;
        }
        $scope.$digest();
      };
    });

    $scope.addContactDialog = function() {
      jQuery("#organization-add-contact-dialog").dialog({
        title: "New Contact",
        width: 800,
        maxHeight: 600,
        modal: true,
        show: {
          effect: "fade",
          duration: 300
        },
        hide: {
          effect: "fade",
          duration: 300
        }
      });
    };

    $scope.organizationAddContactCancel = function() {
      jQuery("#organization-add-contact-dialog").dialog("close");
    };

    $scope.organizationAddContactSave = function() {
      $scope.newcontact.organization_id = $scope.organization.organization_id;

      $http({
        method: 'POST',
        url: '/admin/organization_add_contact',
        data: jQuery.param(angular.copy($scope.newcontact)),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).
      success(function(data, status, headers, config) {
        $scope.organization.contacts = data;
        jQuery("#organization-add-contact-dialog").dialog("close");
      }).
      error(function(data, status, headers, config) {
        error("Error Saving Contact", data, true, null);
      });
    };

    $scope.organizationRemoveContact = function(organization_contact_id) {
      nerivon_confirm("Are you sure?", "This contact will be removed from this organization immediately but will remain in the system in case it's needed again.", "warning", true, function() {
        let obj = {
          organization_id: $scope.organization.organization_id,
          organization_contact_id: organization_contact_id
        };
        $http({
          method: 'POST',
          url: '/admin/organization_remove_contact',
          data: jQuery.param(obj),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).
        success(function(data, status, headers, config) {
          $scope.organization.contacts = data;
        }).
        error(function(data, status, headers, config) {
          error("Error Removing Contact", data, true, null);
        });
      });
    };

    $scope.sendInvitation = function() {
      $http.get('/admin/send_invitation/' + $scope.organization.organization_id).
      success(function(data, status, headers, config) {
        success("Invitation Sent", "An invitation was sent to all available contacts.", true, null);
      }).
      error(function(data, status, headers, config) {
        error("Error Sending Invitation", data, true, null);
      });
    };

    $scope.approve_request = function(index) {
      let action = "create a user account for the requestor to update the profile";

      if ($scope.organization.approved == 0) {
        action += " and will also approve the profile";
      }

      nerivon_confirm("Are you sure?", "Approving this request will " + action + ".",
        "warning", true,
        function() {
          $http.get('/admin/approve_request/' + $scope.organization.requests[index].organization_request_id).
          success(function(data, status, headers, config) {
            $scope.organization.approved = data.approved;
            $scope.organization.requests = data.requests;
          }).
          error(function(data, status, headers, config) {
            error("Error Approving Request", data, true, null);
          });
        });
    };

    $scope.deny_request = function(index) {
      action = "";

      if ($scope.organization.requests[index].new_organization == 1) {
        action = "delete the profile and the request";
      } else {
        action = "delete the request";
      }

      nerivon_confirm("Are you sure?", "Denying this request will " + action + ".",
        "warning", true,
        function() {
          $http.get('/admin/deny_request/' + $scope.organization.requests[index].organization_request_id).
          success(function(data, status, headers, config) {
            if ($scope.organization.requests[index].new_organization) {
              window.location = "/admin/organizations";
            } else {
              $scope.organization.requests = data;
            }
          }).
          error(function(data, status, headers, config) {
            error("Error Denying Request", data, true, null);
          });
        });
    };

    $scope.has_next = function() {
      try {
        var index = $scope.org_ids.indexOf($scope.organization.organization_id);
        if ($scope.org_ids.length > index + 1) {
          return true;
        }
      } catch (e) {
        return false;
      }

      return false;
    };

    $scope.has_prev = function() {
      try {
        var index = $scope.org_ids.indexOf($scope.organization.organization_id);
        if (index > 0) {
          return true;
        }
      } catch (e) {
        return false;
      }

      return false;
    };

    $scope.next = function() {
      if (jQuery("#organization_form").hasClass("ng-dirty")) {
        nerivon_confirm("Unsaved Changes", "You have unsaved changes.\n\nOpen next organization anyway?", "warning", true, function() {
          $scope._next();
        });
      } else {
        $scope._next();
      }
    };

    $scope._next = function() {
      try {
        var index = $scope.org_ids.indexOf($scope.organization.organization_id);
        if ($scope.org_ids.length > index + 1) {
          $scope.load($scope.org_ids[index + 1]);
        }
      } catch (e) {}
    };

    $scope.prev = function() {
      if (jQuery("#organization_form").hasClass("ng-dirty")) {
        nerivon_confirm("Unsaved Changes", "You have unsaved changes.\n\nOpen previous organization anyway?", "warning", true, function() {
          $scope._prev();
        });
      } else {
        $scope._prev();
      }
    };

    $scope._prev = function() {
      try {
        var index = $scope.org_ids.indexOf($scope.organization.organization_id);
        if (index > 0) {
          $scope.load($scope.org_ids[index - 1]);
        }
      } catch (e) {}
    };

    $scope.update_status = function(index, e) {
      var id = jQuery("#new_status_id").val();
      var text = jQuery("#new_status_id option:selected").text();

      $scope.organization.organization_status_id = id;
      $scope.organization.status = text;
    }

    $scope.getSEOName = function(value) {
      //remove all non-alphanumeric (plus dash and underscore) characters
      //then replace all double-dashes with a single dash
      //then remove any leading or trailing dashes
      return value.replace(/[^A-Za-z0-9\-_]/g, "-").replace(/-{2,10}/g, "-").replace(/(^-|-$)/g, "").toLowerCase();
    }

    $scope.remove_logo = function(event) {
      jQuery("#logo_file_upload").val("");
      $scope.organization.logo_file = null;
      $scope.show_logo = false;
    }

    $scope.remove_picture = function(event) {
      jQuery("#picture_file_upload").val("");
      $scope.organization.picture_file = null;
      $scope.show_picture = false;
    }

    jQuery(document).ready(function() {
      // Do NOT run these before loaded. They break TokenInputs.
      $http.get('/ajaxdata/get_all/tagg?system=1').
      success(function(data, status, headers, config) {
        $scope.system_tags = data;
      }).
      error(function(data, status, headers, config) {
        error("Error Loading System Tags", data, true, null);
      });

      $http.get('/ajaxdata/get_default_system_tag_lists').
      success(function(data, status, headers, config) {

        for (let i = 0; i < data.length; i++) {
          if (data[i].child_tags == null) {
            data[i].child_tags = "[]";
          }

          try {
            data[i].child_tags = JSON.parse(data[i].child_tags);
          } catch (e) {

          }
          $scope.filters[i] = {
            "name": data[i].parent_tag_name,
            "tag_id": Number(data[i].parent_tag_id),
            "value": (typeof $scope.filters[i] == "object" ? data[i].child_tags : null)
          };
        }
        $scope.tags = data;
        $scope.tagsActive = true;

        if (!($scope.tags[0].child_tags.length || $scope.tags[1].child_tags.length)) {
          --$scope.steps;
          $scope.tagsActive = false;
        }
      }).
      error(function(data, status, headers, config) {
        error("Error Loading System Tags", data, true, null);
      });

      $http.get('/ajaxdata/get_all/organization_status').
      success(function(data, status, headers, config) {
        var allowed = [];

        for (var i in data) {
          if (data[i].hidden != 1) {
            allowed[allowed.length] = data[i];
          }
        }
        $scope.statuses = allowed;
      }).
      error(function(data, status, headers, config) {
        error("Error Loading Statuses", data, true, null);
      });

      $http.get('/ajaxdata/get_all/contact').
      success(function(data, status, headers, config) {
        $scope.all_contacts = data;
      }).
      error(function(data, status, headers, config) {
        error("Error Loading Contacts", data, true, null);
      });

      jQuery("#scale_min").change(function() {
        jQuery("#scale_max").attr("min", jQuery("#scale_min").val());
      });
      jQuery("#scale_max").change(function() {
        jQuery("#scale_min").attr("max", jQuery("#scale_max").val());
      });
    });
  }]);
})();
